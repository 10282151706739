import React, {useState} from 'react';
import NavBar from "./NavBar";
import Footer from "./Footer";

const PrivacyPolicyBody = () => {
    return (
        <div className="min-h-screen bg-linear-black-grey text-offWhite">
          <NavBar showButtons={false} />
          <div className="container mx-auto px-4 md:px-6 lg:px-8 mt-20">
            <h1 className="text-xl font-semibold mb-8 font-sans">
                Privacy Policy
            </h1>
            <p className="mb-3 text-m font-sans">
                Last updated: March 29, 2025
            </p>
            <p className="mb-3 text-m font-sans">
                This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service, and informs You about Your privacy rights and how the law protects You.
            </p>
            <p className="mb-3 text-m font-sans">
                We use Your Personal Data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
            </p>
            <p className="mb-3 text-m font-sans">
                We use Your Personal Data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
            </p>
            <h2 className="text-3xl font-semibold mb-3 font-sans">
                Interpretation and Definitions
            </h2>
            <h3 className="text-2xl font-semibold mb-3 font-sans">
                Interpretation
            </h3>
            <p className="mb-3 text-m font-sans">
                The words with initial capital letters have meanings defined under the following conditions. These definitions shall have the same meaning regardless of whether they appear in the singular or plural.
            </p>
            <h3 className="text-2xl font-semibold mb-3 font-sans">
                Definitions
            </h3>
            <p className="mb-3 text-m font-sans font-semibold">
                For the purposes of this Privacy Policy:
            </p>
            <ul >
                <li className="mb-3 text-m font-sans"> 
                    "We", "Us", "Our", or “Mantis” refer to the Mantis AI team, started by Prof. Manolis Kellis from the Massachusetts Institute of Technology (MIT) Computer Science and Artificial Intelligence Laboratory (CSAIL) and his colleagues working on the Mantis AI project described at the Website.
                </li>
                <li className="mb-3 text-m font-sans"> 
                    “You” refers to an individual accessing or using the Service, or the company or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                </li>
                <li className="mb-3 text-m font-sans">
                     Service refers to the cognitive cartography and navigation capabilities provided by Our platform extension, which organizes information conceptually into a landscape of ideas and concepts, and provides interactive tools for selecting, navigating, grouping, analyzing, and interacting with the concepts, data, and information provided on the specific Website you are visiting.
                </li>
                <li className="mb-3 text-m font-sans"> 
                    Account means a unique account created for You to access our Service or parts of our Service.
                </li>
                <li className="mb-3 text-m font-sans"> 
                    Affiliate means an entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for the election of directors or other managing authority.
                </li>
                <li className="mb-3 text-m font-sans"> 
                    Cookies are small files that are placed on Your computer, mobile device, or any other device by a website, containing details of Your Browse history on that website, among other uses.
                </li>
                <li className="mb-3 text-m font-sans">
                    Country refers to: Massachusetts, United States.
                </li>
                <li className="mb-3 text-m font-sans">
                   Device means any device that can access the Service, such as a computer, a cellphone, or a digital tablet.
                </li>
                <li className="mb-3 text-m font-sans">
                    Personal Data is any information that relates to an identified or identifiable individual.
                </li>
                <li className="mb-3 text-m font-sans"> 
                    Service Provider means any natural or legal person who processes data on behalf of Us. This includes third-party companies or individuals employed by Mantis to facilitate the Service, to provide the Service on behalf of Mantis, to perform services related to the Service, or to assist Mantis in analyzing how the Service is used.
                </li>
                <li className="mb-3 text-m font-sans"> 
                    Third-party Social Media Service refers to any website or social network website through which a User can log in or create an account to use the Service.
                </li>
                <li className="mb-3 text-m font-sans"> 
                    Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                </li>
                <li className="mb-3 text-m font-sans"> 
                    Website refers to the Mantis website, accessible from <a className="text-decoration-line: underline hover:text-highlightPrimary" href="https://home.withmantis.com/">https://home.withmantis.com</a>.
                </li>
                <li className="mb-3 text-m font-sans"> 
                    Space (or Spaces) refers to Your creation of a cognitive cartography map through the platform by uploading data.
                </li>
                <li className="mb-3 text-m font-sans"> 
                    Extension refers to the Mantis Chromium Extension or Mantis Chrome Extension, which allows You to upload data from any website You visit through the Google Chrome browser or any other compatible browser.
                </li>
            </ul>
            <h2 className="text-3xl font-semibold mb-3 font-sans">
                Collecting and Using Your Personal Data
            </h2>
            <h3 className="text-2xl font-semibold mb-3 font-sans">
                Types of Data Collected
            </h3>
            <h4 className="mb-3 text-lx2 font-sans font-bold">
                Personal Data
            </h4>
            <p className="mb-3 text-m font-sans">
                While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
            </p>
            <ul>
                <li className="mb-3 text-m font-sans">
                    Email address
                </li>
                <li className="mb-3 text-m font-sans">
                    First name and last name
                </li>
                <li className="mb-3 text-m font-sans">
                    Usage Data
                </li>
                <li className="mb-3 text-m font-sans">
                    Data uploaded to Mantis
                </li>
            </ul>
            <h4 className="mb-3 text-lx2 font-sans font-bold">
                Usage Data
            </h4>
            <p className="mb-3 text-m font-sans">
                We collect Usage Data when using the Service. This includes the types of interactions that You have with Our tools, including the actions You take within the Platform, the queries You make and responses You receive, the errors and warning that result from Your usage, the feedback that You provide to the platform, Your patterns of utilization, and a log of your activities. 
            </p>
            <p className="mb-3 text-m font-sans">
                We use Your information to build predictive models that can improve Our system for You and other users, to customize the system to improve Your experience and the experience of other users with similar interests, and to improve the responses of Our system. 
            </p>
            <p className="mb-3 text-m font-sans"> 
                Usage Data may include information such as Your Device's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.
            </p>
            <p className="mb-3 text-m font-sans">
                When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device's unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers, and other diagnostic data.
            </p>
            <p className="mb-3 text-m font-sans">
                We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
            </p>
            <h4 className="mb-3 text-lx2 font-sans font-bold">
                Data Uploaded to Mantis
            </h4>
            <p className="mb-3 text-m font-sans">
                When creating a cognitive cartography space on the Mantis platform, You allow us to use the data you upload to Mantis. We use Your uploaded datasets to provide You insights about Your own datasets, and their relationship with other public data online, or additional data that You have uploaded to Mantis, for example, in creating multi-floor spaces that link different data types to each other. 
            </p>
            <p className="mb-3 text-m font-sans">
                In providing Our services, we carry out training of machine learning models to improve Your experience and to improve Our models and services through techniques that include backpropagation, gradient descent, parameter tuning, fine-tuning, reinforcement learning, reinforcement learning with human feedback, and agentic workflows. 
            </p>
            <p className="mb-3 text-m font-sans">
                In providing Our services, we will sometimes also share your data with Third-Party Companies that provide AI Models according to their respective privacy policies, including, but not limited to:
            </p>
            <ul>
                <li className="mb-3 text-m font-sans">
                    Google (Gemini), <a className="text-decoration-line: underline hover:text-highlightPrimary" href="https://support.google.com/gemini/answer/13594961">https://support.google.com/gemini/answer/13594961</a>
                </li>
                <li className="mb-3 text-m font-sans">
                    OpenAI (ChatGPT), <a className="text-decoration-line: underline hover:text-highlightPrimary" href="https://openai.com/policies/row-privacy-policy/">https://openai.com/policies/row-privacy-policy/</a>
                </li>
                <li className="mb-3 text-m font-sans">
                    HuggingFace, <a className="text-decoration-line: underline hover:text-highlightPrimary" href="https://huggingface.co/privacy">https://huggingface.co/privacy</a>
                </li>
            </ul>
            <p className="mb-3 text-m font-sans">
                While Our Team strives to secure Your data, Our software is still in development and being tested for vulnerabilities. You must exercise caution when uploading sensitive information.
            </p>
            <h4 className="mb-3 text-lx2 font-sans font-bold">
                Information from Third-Party Social Media Services
            </h4>
            <p className="mb-3 text-m font-sans">
                We allow You to create an account and log in to use the Service through the following Third-party Services:
            </p>
            <ul>
                <li className="mb-3 text-m font-sans"> Google </li>
                <li className="mb-3 text-m font-sans"> GitHub </li>
            </ul>    
            <p className="mb-3 text-m font-sans">
                If You decide to register through or otherwise grant us access to a Third-Party Service, We may collect Personal Data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities, or Your contact list associated with that account.
            </p>
            <p className="mb-3 text-m font-sans">
                You may also have the option of sharing additional information with Us through Your Third-Party Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving Us permission to use, share, and store it in a manner consistent with this Privacy Policy.
            </p>
            <h4 className="mb-3 text-lx2 font-sans font-bold">
                Tracking Technologies and Cookies
            </h4>
            <p className="mb-3 text-m font-sans">
                We use Cookies and similar tracking technologies to track activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:
            </p>
            <ol>
                <li className="mb-3 text-m font-sans"> 
                    <b>Cookies or Browser Cookies:</b> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless You have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.                
                </li>
                <li className="mb-3 text-m font-sans"> 
                    <b>Web Beacons:</b> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit Us, for example, to count users who have visited those pages or opened an email, and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).
                </li>
            </ol> 
            <p className="mb-3 text-m font-sans">
                Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.
            </p>
            <p className="mb-3 text-m font-sans">
                Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.
            </p>
            <p className="mb-3 text-m font-sans">
                We use both Session and Persistent Cookies for the purposes set out below:
            </p>
            <ul>
                <li className="mb-3 text-m font-sans"> 
                    <b>Necessary / Essential Cookies</b> 
                    <ul> 
                        <li> 
                            Type: Session Cookies 
                        </li>
                        <li className="mb-3 text-m font-sans"> 
                            Administered by: Us 
                        </li>
                        <li> 
                            <b>Purpose:</b> These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.
                        </li>
                    </ul>
                </li>
                <li className="mb-3 text-m font-sans"> 
                    <b>Cookies Policy / Notice Acceptance Cookies</b> 
                    <ul> 
                        <li> 
                            Type: Persistent Cookies 
                        </li>
                        <li className="mb-3 text-m font-sans"> 
                            Administered by: Us 
                        </li>
                        <li> 
                            <b>Purpose:</b> These Cookies identify if users have accepted the use of cookies on the Website.
                        </li>
                    </ul>
                </li>
                <li className="mb-3 text-m font-sans"> 
                    <b>Functionality Cookies</b> 
                    <ul> 
                        <li> 
                            Type: Persistent Cookies 
                        </li>
                        <li className="mb-3 text-m font-sans"> 
                            Administered by: Us 
                        </li>
                        <li> 
                            <b>Purpose:</b> These Cookies allow us to remember choices You make when You use the Website, such as remembering Your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter Your preferences every time You use the Website.
                        </li>
                    </ul>
                </li>
                </ul>
                <h2 className="text-3xl font-semibold mb-3 font-sans">
                    Collecting and Using Your Personal Data
                </h2>
                <p className="mb-3 text-m font-sans">
                    We may use Personal Data for the following purposes:
                </p>
                <ul>
                    <li className="mb-3 text-m font-sans">
                        To provide and maintain our Service, including to monitor its usage.
                    </li>
                    <li className="mb-3 text-m font-sans">
                        To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
                    </li>
                    <li className="mb-3 text-m font-sans">
                        For the performance of a contract: the development, compliance, and execution of the purchase contract for the products, items, or services You have purchased, or of any other contract with Us through the Service.
                    </li>
                    <li className="mb-3 text-m font-sans">
                        To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications, regarding updates or informative communications related to the functionalities, products, or contracted services, including security updates, when necessary or reasonable for their implementation.
                    </li>
                    <li className="mb-3 text-m font-sans">
                        To provide You with news, special offers, and general information about other goods, services, and events which we offer that are similar to those that You have already purchased or enquired about, unless You have opted not to receive such information.
                    </li>
                    <li className="mb-3 text-m font-sans">
                        To manage Your requests: To attend to and manage Your requests to Us.
                    </li>
                    <li className="mb-3 text-m font-sans">
                        For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
                    </li>
                    <li className="mb-3 text-m font-sans">
                        For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and to evaluate and improve our Service, products, services, marketing, and Your experience.
                    </li>
                </ul>
                <p className="mb-3 text-m font-sans">
                    We may share Your personal information in the following situations:
                </p>
                <ul>
                    <li className="mb-3 text-m font-sans">
                        With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service and to contact You.
                    </li>
                    <li className="mb-3 text-m font-sans">
                        For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Our assets, financing, or acquisition of all or a portion of Our business by another company.
                    </li>
                    <li className="mb-3 text-m font-sans">
                        With AI models: We may share or use Your personal information (specifically data that You upload to Mantis) to be used with Third-Party AI Models, as mentioned earlier.
                    </li>
                    <li className="mb-3 text-m font-sans">
                        With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners, or other companies that We control or that are under common control with Us.
                    </li>
                    <li className="mb-3 text-m font-sans">
                        With business partners: We may share Your information with Our business partners to offer You certain products, services, or promotions.
                    </li>
                    <li className="mb-3 text-m font-sans">
                        With other users: When You share personal information or otherwise interact in public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures, and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You, and view Your profile.
                    </li>
                    <li className="mb-3 text-m font-sans">
                        With Your consent: We may disclose Your personal information for any other purpose with Your consent.
                    </li>
                </ul>
                <h2 className="text-3xl font-semibold mb-3 font-sans">
                    Collecting and Using Your Personal Data
                </h2>
                <p className="mb-3 text-m font-sans">
                    We will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
                </p>
                <p className="mb-3 text-m font-sans">
                    We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or when We are legally obligated to retain this data for longer time periods.
                </p>
                <h2 className="text-3xl font-semibold mb-3 font-sans">
                    Transfer of Your Personal Data
                </h2>
                <p className="mb-3 text-m font-sans">

                </p>
                <p className="mb-3 text-m font-sans">
                    Your information, including Personal Data, is processed at Our operating offices and in any other places where the parties involved in the processing are located. This means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of Your jurisdiction.
                </p>
                <p className="mb-3 text-m font-sans">
                    Your consent to this Privacy Policy, followed by Your submission of such information, represents Your agreement to that transfer.
                </p>
                <p className="mb-3 text-m font-sans">
                    We will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy, and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place, including the security of Your data and other personal information.
                </p>
                <h2 className="text-3xl font-semibold mb-3 font-sans">
                    Delete Your Personal Data
                </h2>
                <p className="mb-3 text-m font-sans">
                    You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.
                </p>
                <p className="mb-3 text-m font-sans">
                    Our Service may give You the ability to delete certain information about You from within the Service.
                </p>
                <p className="mb-3 text-m font-sans">
                    You may update, amend, or delete Your information at any time by signing in to Your Account, if You have one, and visiting the account settings section that allows You to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.
                </p>
                <p className="mb-3 text-m font-sans">
                    Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.
                </p>
                <h2 className="text-3xl font-semibold mb-3 font-sans">
                    Disclosure of Your Personal Data
                </h2>
                <p className="mb-3 text-m font-sans">
                    If Mantis is involved in a merger, acquisition, or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
                </p>
                <h2 className="text-3xl font-semibold mb-3 font-sans">
                    Law Enforcement
                </h2>
                <p className="mb-3 text-m font-sans">
                    Under certain circumstances, We may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).
                </p>
                <h2 className="text-3xl font-semibold mb-3 font-sans">
                    Other Legal Requirements
                </h2>
                <p className="mb-3 text-m font-sans">
                    We may disclose Your Personal Data in the good faith belief that such action is necessary to:
                </p>
                <ul> 
                    <li className="mb-3 text-m font-sans">
                        Comply with a legal obligation.
                    </li>
                    <li className="mb-3 text-m font-sans"> 
                        Protect and defend Our rights or property.
                    </li>
                    <li className="mb-3 text-m font-sans"> 
                        Prevent or investigate possible wrongdoing in connection with the Service.
                    </li>
                    <li className="mb-3 text-m font-sans"> 
                        Protect the personal safety of Users of the Service or the public.
                    </li>
                    <li className="mb-3 text-m font-sans"> 
                        Protect against legal liability
                    </li>
                </ul>
                <h2 className="text-3xl font-semibold mb-3 font-sans">
                    Security of Your Personal Data
                </h2>
                <p className="mb-3 text-m font-sans">
                    The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
                </p>
                <h2 className="text-3xl font-semibold mb-3 font-sans">
                    Children's Privacy
                </h2>
                <p className="mb-3 text-m font-sans">
                    Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
                </p>
                <p className="mb-3 text-m font-sans">
                    If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.
                </p>
                <h2 className="text-3xl font-semibold mb-3 font-sans">
                    Links to Other Websites
                </h2>
                <p className="mb-3 text-m font-sans">
                    Our Service may contain links to other websites that are not operated by Us. If You click on a third-party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
                </p>
                <p className="mb-3 text-m font-sans">
                    We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                </p>
                <h2 className="text-3xl font-semibold mb-3 font-sans">
                   Changes to this Privacy Policy
                </h2>
                <p className="mb-3 text-m font-sans">
                    We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
                </p>
                <p className="mb-3 text-m font-sans">
                    We will let You know via email and/or a prominent notice on Our Service prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
                </p>
                <p className="mb-3 text-m font-sans">
                    You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                </p>
                <h2 className="text-3xl font-semibold mb-3 font-sans">
                   Contact Us
                </h2>
                <p className="mb-6 text-m font-sans"> {/** Using mb-6 for extra spacing **/}
                    If You have any questions about this Privacy Policy, You can contact us by visiting this page on our website: <a href="https://home.withmantis.com/inquire" className="text-decoration-line: underline hover:text-highlightPrimary">https://home.withmantis.com/inquire</a>.
                </p>
                <div className="mb-3"></div>
            </div>
            <Footer></Footer>
        </div>
      );
}

export default PrivacyPolicyBody;