import React from "react";
import Logo from "../assets/images/mantis-logo-white.svg";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-offBlack text-offWhite py-4">
      <div className="container mx-auto flex items-center justify-between px-4">
        <div className="flex items-center space-x-2">
          <img src={Logo} alt="Mantis AI" className="h-8 md:h-10" />
          <span className="text-base md:text-lg font-medium">Mantis</span>
          <span>  Copyright © {new Date().getFullYear()} </span>
          {/*
          Optional Link to Privacy Policy (add a separator like | to the Span above)
          <Link to="/privacy-policy">
          <span className="text-decoration-line: underline hover:text-highlightPrimary">Privacy Policy</span> 
          </Link>
          */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
