import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import "../assets/styles/customStyles.css";
import particlesJS from "particles.js";
import Map from "../assets/images/map.png";
import Team from "../assets/images/team.png";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import Footer from "./Footer";

const Home = () => {
  const aboutRef = useRef(null);
  const teamRef = useRef(null);

  useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 200,
          density: {
            enable: true,
            value_area: 800,
          },
        },
        color: {
          value: ["#FF5733", "#33FF57", "#3357FF", "#F3FF33", "#DA70D6"],
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#f3f3f3",
          },
          polygon: {
            nb_sides: 3,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 4,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#f3f3f3",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 3,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "bubble",
          },
          onclick: {
            enable: false,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 400,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 5,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  }, []);

  const scrollToSection = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <NavBar />
      <div id="home-section" className="flex justify-center h-screen bg-black relative">
        <div id="particles-js" className="w-full h-3/4 absolute top-0"></div>
        <div className="container mx-auto flex flex-col justify-end h-full px-4 md:px-6 lg:px-8 pb-24 2xl:pb-48">
          <div className="w-full md:w-3/5 z-10">
          <div className="w-full md:w-3/5 z-10">
  <h2 className="text-lg md:text-lg lg:text-4xl font-bold font-sans text-highlightSecondary text-left text-white">
    Navigate Your Data:
  </h2>
  <h1 className="text-4xl md:text-4xl lg:text-6xl font-bold font-sans text-white leading-snug text-left">
    Cognitive Cartography
    <br className="md:hidden" /> for the Digital Age
  </h1>
</div>

          </div>
          <div className="mt-8 flex justify-start w-full">
  <button
    className="bg-offWhite hover:bg-highlightPrimary active:bg-highlightSecondary text-offBlack hover:text-offWhite py-3 px-6 rounded-lg font-semibold transition duration-300 text-base w-full md:w-auto flex items-center justify-center space-x-2"
    onClick={() => scrollToSection(aboutRef)}
  >
    <span>Learn More</span>
  </button>
</div>
        </div>
      </div>
      <div id="about-section" ref={aboutRef} className="bg-linear-black-blue text-white py-8 md:py-12">
        <div className="container mx-auto px-4 md:px-6 lg:px-8 flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h2 className="text-3xl md:text-5xl lg:text-6xl font-medium font-sans mb-6">
              About
            </h2>
            <p className="mb-4">
              Utilizing advanced AI, Mantis transforms complex data landscapes
              into visual, navigable maps, enabling businesses to uncover
              inefficiencies and synchronize team efforts effectively.
            </p>
            <p className="mb-4">
              Pioneering digital cognitive cartography, Mantis redefines data
              interaction and comprehension.
            </p>
            <p className="mb-4">
              Explore new territories in knowledge navigation, launching in
              2024.
            </p>
            <div className="flex justify-center md:justify-start mt-8">
            <div className="flex justify-center md:justify-start mt-8 w-full">
    <Link to="/inquire" className="w-full md:w-auto">
        <button className="bg-subtleActionGray hover:bg-highlightPrimary active:bg-highlightSecondary text-white font-semibold py-3 px-6 rounded-lg flex items-center justify-center space-x-2 hover:animate-moveArrow active:animate-none w-full">
            <span>Request a Demo</span>
            <ArrowRightIcon className="h-5 w-5 move-arrow" />
        </button>
    </Link>
</div>
            </div>
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center">
            <img src={Map} alt="Map" className="w-full max-w-[500px] h-auto rounded-lg" />
          </div>
        </div>
      </div>
      <div id="team-section" ref={teamRef} className="bg-linear-blue-grey text-white py-8 md:py-12">
        <div className="container mx-auto px-4 md:px-6 lg:px-8 flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h2 className="text-3xl md:text-5xl lg:text-6xl font-medium font-sans mb-6">
              Our Team
            </h2>
            <p className="mb-4">
              At Mantis, we are committed to innovation in AI and data
              visualization.
            </p>
            <p className="mb-4">
              Born from MIT’s Kellis Lab and CSAIL, our team of AI specialists
              and data scientists brings diverse expertise to cognitive
              cartography.
            </p>
            <p className="mb-4">
              We aim to revolutionize how businesses leverage data for strategic
              decisions, setting new industry standards.
            </p>
            <p className="mb-4">
              If you are passionate about transforming complex data into
              actionable insights, join us in shaping the future of data
              exploration.
            </p>
            <div className="flex justify-center md:justify-start mt-8 w-full">
    <Link to="/join" className="w-full md:w-auto">
        <button className="bg-subtleActionGray hover:bg-highlightPrimary active:bg-highlightSecondary text-white font-semibold py-3 px-6 rounded-lg flex items-center justify-center space-x-2 hover:animate-moveArrow w-full">
            <span>Get Involved</span>
            <ArrowRightIcon className="h-5 w-5 move-arrow" />
        </button>
    </Link>
</div>
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center">
            <img src={Team} alt="Team" className="w-full max-w-[500px] h-auto rounded-lg" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
