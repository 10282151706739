import React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './App.css';
import Inquire from './components/Inquire';
import Home from './components/Home';
import ThankYou from './components/ThankYou';
import JoinUs from './components/JoinUs';
import Thanks from './components/Thanks';
import PrivacyPolicy from './components/PrivacyPolicy'

const router = createBrowserRouter([ 
  {
    path: "/",
    element: <Home />,
  }, 
  {
    path: "/inquire",
    element: <Inquire />,
  }, 
  {
    path: "/thankyou",
    element: <ThankYou />,
  }, 
  {
    path: "/join",
    element: <JoinUs />,
  }, 
  {
    path: "/thanks",
    element: <Thanks />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  }
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
